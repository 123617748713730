import { Component, OnInit, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  display = true;
  path: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2,private _location: Location) {
    if (!isPlatformBrowser(this.platformId)) {
      this.display = false;
    }
  }

  ngOnInit() {
    let tempPath = this._location.path().split('/');
    this.path = tempPath[1]
  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      let loader = this.renderer.selectRootElement('#loader');
      if (loader.style.display != "none"){
          loader.style.display = "none"; //hide loader
      }
    }

  }

}
