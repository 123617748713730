import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { 
	TransactionURL, AddFundURL, AddPaypalFundURL,
    AddCardURL, AddCardNewURL, ListCardURL, EditCardURL, DeletetCardURL, ListCredCardURL, 
	GetCredCardInfoURL, WithdrawFundURL, PurchaseCardURL, ValidatePromocodeUrl, TransactionByIdURL,
	ListBankAccountsURL, withdrawViaNewACHURL, withdrawViaACHURL, deleteACHURL,SendEmailVerificationLinkURL,ProfileInfoURL, BalanceInfo, ExistBankAccounts, NuveiAccountCaptureUrl, NuveiWithdrawFunds, deleteAccount, validateIPAddress, GetNuveiibtURL
} from '../constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap, map, startWith } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
    
    public Transation 
    public ListCredCard;
	public accountId: any;
	public companyId: any;

	constructor(private http: HttpClient,
    private router: Router) {
		
		this.companyId = environment.company_id;
	}
    
    // Trasaction
    public get transaction(): Observable<any> {
			this.Transation = this.http.get<any>(TransactionURL).pipe(map(data => data));
			//console.log("555");
			// this.Transation.subscribe(next =>{ localStorage['transation'] = JSON.stringify(next) });
	    return this.Transation; // = this.Transation.pipe(startWith(JSON.parse(localStorage['transation'] || '[]')));
    }

		public transactionById(accountId: string): Observable<any> {  
			let url = TransactionByIdURL+"?accountId="+accountId;
			this.Transation = this.http.get<any>(url).pipe(map(data => data));
			return this.Transation;
		}

    // Add credit card
    public addFund(value): Observable<any> {
    	if(value.type == 'Creditcard') {
			const params = {
				"amount": value.amount,
				"cardId": value.cardId,
				"promoCode": value.promoCode
			}

		    let response = this.http.post<any>(AddFundURL, params).pipe(tap(res => {
				localStorage['u_b_i'] = JSON.stringify(response);
				localStorage.removeItem('depositPromoCode');  
				localStorage.removeItem('credCardAmount');
			}));
			
			return response;

		} 
    }

    // Add credit card
    public addCreditCard(value): Observable<any> {
			const params = {
					"cardType": value.cardType,
					"cardNumber": value.cardNumber.replace(/\D/g, ''),
					"securityCde": value.securityCode,
					"expiryMonth": value.expMonthYear.replace(/\D/g, '').slice(0,2),
					"expiryYear": value.expMonthYear.replace(/\D/g, '').slice(2,4),
					"fullName": value.fullName,
					"billingAddress": value.billingAddress,
					"city": value.city,
					"state": value.state,
					"country": value.country,
					"zipCode": value.zipCode,
					"amount": localStorage.getItem("credCardAmount"),
					"saveCardForFuture": (value.saveCardFor == null || value.saveCardFor == undefined || value.saveCardFor == "") ? false : value.saveCardFor,
					"promoCode": localStorage.getItem('depositPromoCode') 
		    };
			
		    return this.http.post<any>(AddCardNewURL, params).pipe(tap(res => {
				localStorage['u_b_i'] = JSON.stringify(res);
				localStorage.removeItem('depositPromoCode');  
				localStorage.removeItem('credCardAmount');  
			}));
    }

    public validatePromo(code): Observable<any> {
	    return this.http.get<any>(ValidatePromocodeUrl+"?code="+code).pipe(tap(res => res))
    }

    public infoCreditCard(id): Observable<any> {
	    return this.http.get<any>(GetCredCardInfoURL+"/"+id).pipe(tap(res => res))
    }

    public editCreditCard(value): Observable<any> {
    	const params = {
				"cardId": value.cardId,
				"expiryMonth": value.expMonthYear.replace(/\D/g, '').slice(0,2),
				"expiryYear": value.expMonthYear.replace(/\D/g, '').slice(2,4),
			    "fullName": value.fullName,
				"billingAddress": value.billingAddress,
				"city": value.city,
				"state": value.state,
				"country": value.country,
				"zipCode": value.zipCode,
			};
			console.log(params);
			
		    
		    return this.http.post<any>(EditCardURL, params)
		        .pipe(tap(res => res))
    }

    public removeCreditCard(value): Observable<any> {
		const options = {
		  headers: new HttpHeaders({
		    'Content-Type': 'application/json',
		  }),
		  body: {
		    cardId: value.id
		  },
		};
	    return this.http.delete<any>(DeletetCardURL, options)
    }
   
	public sendEmailVerificationLink(): Observable<any> {
		const options = {
		  headers: new HttpHeaders({
		    'Content-Type': 'application/json',
		  }),
		};
	    return this.http.post<any>(SendEmailVerificationLinkURL, options)
    }
	
	public profileInfo(): Observable<any> {
		const options = {
		  headers: new HttpHeaders({
		    'Content-Type': 'application/json',
		  }),
		};
	    return this.http.get<any>(ProfileInfoURL, options)
    }

    // Trasaction 
    public get listCredCard(): Observable<any> {
    	this.ListCredCard = this.http.get<any>(ListCredCardURL).pipe(map(data => data));
	    // this.ListCredCard.subscribe(next =>{ localStorage['listCredCard'] = JSON.stringify(next) });
	    return this.ListCredCard; // = this.ListCredCard.pipe(startWith(JSON.parse(localStorage['listCredCard'] || '[]')));
		}


	// public validateIPAddress(): Observable<any> {
	//     return this.http.get<any>(validateIPAddress).pipe(tap(res => res))
    // }
	public validateIPAddress(): Observable<any> {
		this.accountId = JSON.parse(localStorage.getItem("accountInfo"))._id;
	    return this.http.get<any>(`${validateIPAddress}?company_id=${this.companyId}&account_id=${this.accountId}`).pipe(tap(res => res))
    }

	public withdrawaViaNewACHAccount(data): Observable<any> {
    	return this.http.post<any>(withdrawViaNewACHURL, data).pipe(tap(res => this.resetAccountBalance(res)))
	}
	public withdrawaViaACHAccount(data): Observable<any> {
    	return this.http.post<any>(withdrawViaACHURL, data).pipe(tap(res => this.resetAccountBalance(res)))
	}
	public deleteACHAccount(value): Observable<any> {
		const options = {
		  headers: new HttpHeaders({
		    'Content-Type': 'application/json',
		  }),
		  body: value
		};
	    return this.http.delete<any>(deleteACHURL, options)
    }

	public deleteAccount(value): Observable<any> {
		const options = {
		  headers: new HttpHeaders({
		    'Content-Type': 'application/json',
		  }),
		  body: value
		};
	    return this.http.delete<any>(deleteAccount, options)
    }
		
	public purchaseCard(value): Observable<any> {
		return this.http.post<any>(PurchaseCardURL, value).pipe(tap(res => this.resetAccountBalance(res)))
	}

	resetAccountBalance(balance: any){
		var accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
		accountInfo.account_balance = balance;
		localStorage.removeItem("accountInfo");
		localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
	}

	public get balanceInfo(): Observable<any> {
		return this.http.get<any>(BalanceInfo);
	}
	public get existBankAccounts(): Observable<any> {
		return this.http.get<any>(ExistBankAccounts);
	}
	public nuveiAccountCaptureUrl(data): Observable<any> {
    	return this.http.post<any>(NuveiAccountCaptureUrl, data).pipe(tap(res => this.resetAccountBalance(res)))
	}
	public nuveiWithdrawFunds(data): Observable<any> {
    	return this.http.post<any>(NuveiWithdrawFunds, data).pipe(tap(res => this.resetAccountBalance(res)))
	}
	public getNuveiibtURL(data): Observable<any> {
    	return this.http.post<any>(GetNuveiibtURL, data).pipe(tap(res => this.resetAccountBalance(res)))
	}

}