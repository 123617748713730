// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  display_banners:false,
  company_id: "5",
   API_URL: "https://api.playsqor.com/api/",
   VALIDATE_IP_API_URL: "https://vetnosmaster-backofficeapi-prod.azurewebsites.net/api/",
  // API_URL: "https://api.playsqor.com/api/",
  // API_URL: "https://localhost:44340/api/",

  TEXT_ENCRYPTION_DECRYPTION_PASSWORD: 'JLJ4KJKS4DJKSD4E0IUYTWIU7YEY12WE&',

  MIXPANEL_TOKEN: '401ccbbb9b7d832b4d4d99fa486d36cf',
  FRESHDESK_KEY: 'Wkh1YUV1anFNTUlqanBab0s6',
  FRESHDESK_BASEURL: 'https://playbawk.freshdesk.com/api/v2/tickets'
};
  
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
