import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { League, Card, MyCards, SQORRTV } from '../model/cards';
import { LeagueURL, cardURL, SQORRTVURL, Mycards, LocationInfo, MycardsById, encryption_decryption_password, SportsURL, 
  ClaimGEPPrizeUrl, SettleCardUrl, PlayBingoUrl, BingoPlayInfoUrl, ClaimGWPUrl, HowToVideo } from '../constants';
import { Observable, Subject } from 'rxjs';
import { map, tap, startWith, shareReplay, publishReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class CardService {
  
  public League
  public Sports
  public Card
  public SQORRTV
  public Mycard 
  public cardDetail

  constructor(private http: HttpClient) { }
 
  public get sports(): Observable<any[]> {
    this.Sports = this.http.get<any[]>(SportsURL).pipe(map(data => data));
    this.Sports.subscribe(next =>{ localStorage['sports'] = JSON.stringify(next) });
    return this.Sports = this.Sports.pipe(startWith(JSON.parse(localStorage['sports'] || '[]')));
  }

  public get leagues(): Observable<League[]> {
    this.League = this.http.get<League[]>(LeagueURL).pipe(map(data => data));
    this.League.subscribe(next =>{ localStorage['league'] = JSON.stringify(next) });
    return this.League = this.League.pipe(startWith(JSON.parse(localStorage['league'] || '[]')));
  }

  public fetchLeagueData(): Promise<any> {
    return this.http.get(LeagueURL).toPromise();
  }

  public fetchSportsData(): Promise<any> {
    return this.http.get(SportsURL).toPromise();
  }

  public cards(): any {
    var cards = localStorage.getItem("_active_cards_data") || null;
    // if(cards != null){
    //   var cards_data = JSON.parse(CryptoJS.AES.decrypt(cards, encryption_decryption_password).toString(CryptoJS.enc.Utf8));
    //   var time_diff_in_minutes = Math.abs(Math.round(((new Date().getTime() - cards_data.fetch_time) / 1000)/60));
    //   console.log(time_diff_in_minutes);
    //   if(time_diff_in_minutes <= 1){
    //     console.log("Returning data from local storage");
    //     return cards_data.data;
    //   }
    // }
    console.log("fetching data from API");
    return this.http.get<any>(cardURL).pipe(map(data => {
      let _cards_data: any= {};
      _cards_data.fetch_time = new Date().getTime(); 
      _cards_data.data = data;
      
      localStorage.removeItem("_active_cards_data");
      localStorage.setItem('_active_cards_data', CryptoJS.AES.encrypt(JSON.stringify(_cards_data), encryption_decryption_password).toString());

      return data;
    }));

    // var cards_ressponse = this.http.get<any>(cardURL).pipe(map(data => data));
    // cards_ressponse.subscribe(next =>{ 
    //   let _cards_data: any= {};
    //   _cards_data.fetch_time = new Date().getTime(); 
    //   _cards_data.data = cards_ressponse;
      
    //   localStorage.removeItem("_active_cards_data");
    //   localStorage.setItem('_active_cards_data', CryptoJS.AES.encrypt(JSON.stringify(_cards_data), encryption_decryption_password).toString());
    // });
    
    // return cards_ressponse ;     
  }

  public get sqorrtvCards(): Observable<SQORRTV[]> {
    this.SQORRTV = this.http.get<SQORRTV[]>(SQORRTVURL).pipe(map(data => data));
    this.SQORRTV.subscribe(next =>{ localStorage['sqorrtv'] = JSON.stringify(next) });
    return this.SQORRTV = this.SQORRTV.pipe(startWith(JSON.parse(localStorage['sqorrtv'] || '[]')));
  }

  public get myCards(): Observable<MyCards[]> {  
    this.Mycard = this.http.get<MyCards[]>(Mycards).pipe(map(data => data));
    //this.Mycard.subscribe(next =>{ localStorage['mycard'] = JSON.stringify(next) });
    return this.Mycard;//= this.Mycard.pipe(startWith(JSON.parse(localStorage['mycard'] || '[]')));
  }

  public myCardsById(accountId: string): Observable<MyCards[]> {  
    let url = MycardsById+"?accountId="+accountId;
    return this.http.get<MyCards[]>(url).pipe(map(data => data));
    //this.Mycard.subscribe(next =>{ localStorage['mycard'] = JSON.stringify(next) });
    //return this.Mycard;
  }

  public DetailCards(id, pcid): Observable<any[]> {
    let detailURL= (pcid != null) ? environment.API_URL+'card/'+id+'/matchups/'+pcid : environment.API_URL+'card/'+id+'/matchups';
    this.cardDetail = this.http.get<any[]>(detailURL).pipe(map(data => data));
    //console.log(this.cardDetail);
    //this.cardDetail.subscribe(next =>{ localStorage['detailCard'] = JSON.stringify(next) });
    return this.cardDetail ; //= this.cardDetail.pipe(startWith(JSON.parse(localStorage['detailCard'] || '[]')));
  }

  public LocationInfo(): Observable<any[]> {
    let locationInfoURL= LocationInfo;
    return this.http.get<any[]>(locationInfoURL).pipe(map(data => data));
  }

  public ClaimGEPPrize(data: any): Observable<any> {
    let claimGEPPrizeUrl= ClaimGEPPrizeUrl;
    return this.http.post<any>(claimGEPPrizeUrl, data).pipe(tap(res => this.resetAccountBalance(res)));
  }
  public PlayBingo(data: any): Observable<any> {
    let playBingoUrl= PlayBingoUrl;
    return this.http.post<any>(playBingoUrl, data).pipe(tap(res => this.resetAccountBalance(res)));
  }
  public ClaimGWP(data: any): Observable<any> {
    return this.http.post<any>(ClaimGWPUrl, data).pipe(map(data => data));
  }
  public BingoPlayInfo(data: any): Observable<any> {
    //let playBingoUrl= PlayBingoUrl;
    return this.http.post<any>(BingoPlayInfoUrl, data).pipe(map(data => data));
  }
  public Savebingoplayentry(data: any): Observable<any> {
    return this.http.post<any>(environment.API_URL+"savebingoplayentry", data).pipe(map(data => data));
  }
  public SettleCard(data: any): Observable<any> {
    return this.http.post<any>(SettleCardUrl, data).pipe(map(data => data));
  }

  public howtoplay(id): Observable<any>{
    let howToPlayAllURL= environment.API_URL+'content/RULES'
    let howToPlayURL= environment.API_URL+'content/htp-'+id
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8')
    headers.set('Cid', (5).toString());
    if(id == 'all')
    {
      console.log(howToPlayAllURL);  
      return this.http.get(howToPlayAllURL,{ headers })
    }
    else
    {
      console.log(howToPlayURL);
      return this.http.get(howToPlayURL,{ headers })
    }
  }
  public progressivePayout(){
   let url="https://imgstore.azureedge.net/payouts/ProgressivePayouts.json"
    return this.http.get<any>(url).pipe(map(data => data));
  }

  public getHowToVideos(): Observable<any> {
    return this.http.get<any>(HowToVideo).pipe(map(data => data));
  }

  resetAccountBalance(response: any){
    if(response.is_success){
      var accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
      accountInfo.account_balance = response.account_balance;
      localStorage.removeItem("accountInfo");
      localStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    }
	}
}
