import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { AppSectionComponent } from './components/app-section/app-section.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';

import { AuthenticationService } from './services/auth.service';
import { AccountService } from './services/account.service';
import { CardService } from './services/card.service';
import { GeneralService } from './services/general.service';
import { TransactionService } from './services/transaction.service';
import { LoaderComponent } from './components/loader/loader.component';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
  exports:[ HeaderComponent, AppSectionComponent, FooterComponent, LoaderComponent ],
  declarations: [HeaderComponent, AppSectionComponent, FooterComponent, LoaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    CarouselModule
  ],
  providers: [
  	AuthenticationService,
  	AccountService,
  	CardService,
  	GeneralService,
    TransactionService
  ]
})
export class SharedModule { }
