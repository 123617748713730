import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../shared/services/auth.service';
import { AccountService } from '../../../shared/services/account.service';
import { CarouselModule } from 'ngx-owl-carousel-o';
import * as moment from "moment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public url: any;

  hideHeader: boolean = false;
  public timeStamp: any;
  balanceInfo: any;
  totalCashBalance: any;

  constructor(
    private modalService: NgbModal,
    public AuthenticationService: AuthenticationService,
    public accountService: AccountService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
    if(this.AuthenticationService.isLoggedIn()){
      this.accountService.Balancenfo();
    }
  }

  ngOnInit() {
    this.accountService.getTotalBalance().subscribe((res) => {
      this.totalCashBalance = res;
    })
  }
  
  ngAfterViewChecked() {
    this.timeStamp = Math.floor(Date.now() / 1000);
    this.cdRef.detectChanges();
  }

  checkImagePath(val) {
    // this.timeStamp = Math.floor(Date.now() / 1000);
    if (val) {
      return val + "?q=" + this.timeStamp;
    } else {
      return "assets/images/profile.png";
    }
  }

  abc(){
    console.log("hi")
  }
}
