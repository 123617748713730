import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ValidateLocation ,AccountInfo,ProfileInfo, EditAccountInfo, UpdatePassword, profileImage, ResetPassword
  , ageverificationImage, validateveficationdocs, statesUrl, BalanceInfo  } from '../constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, startWith } from 'rxjs/operators';
import { environment } from '../../../environments/environment'

//declare var geoip2

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public account
  public balanceInfo
  public tempTotalBalance = JSON.parse(localStorage.getItem('u_b_i'))
  public totalBalance = new BehaviorSubject(this.tempTotalBalance ? this.tempTotalBalance.totalCashBalance : null);


  constructor(private http: HttpClient) {
   
  } 

  public validateLocation(params) {
    return  this.http.post<any>(ValidateLocation, params).pipe(map(res => res ));
  }

  public get accountInfo(): any {
    return JSON.parse(localStorage.getItem('accountInfo'));
  }

  setTotalBalance(val){
    this.totalBalance.next(val);
  }

  getTotalBalance(){
    return this.totalBalance.asObservable();
  }

  public AccountInfo(): any {
    this.account = this.http.get<any>(AccountInfo).pipe(map(data => data));
    this.account.subscribe(next =>{ localStorage['accountInfo'] = JSON.stringify(next) });
    return this.account = this.account.pipe(startWith(JSON.parse(localStorage['accountInfo'] || '[]')));
  }
  public Profilenfo(): any {
    this.account = this.http.get<any>(ProfileInfo).pipe(map(data => data));
    //this.account.subscribe(next =>{ localStorage['accountInfo'] = JSON.stringify(next) });
    return this.account ;
  }
  public Balancenfo(): any {
    this.balanceInfo = this.http.get<any>(BalanceInfo).pipe(map(data => data));
    this.balanceInfo.subscribe(next =>{ localStorage['u_b_i'] = JSON.stringify(next); this.setTotalBalance(next.totalCashBalance) });
    return this.balanceInfo ;
  }
  public  getStates(): any {
    let url = environment.API_URL+"states"
		return this.http.get<any>(url).pipe(map(data => data));
    //return this.http.get<any>(statesUrl).pipe(map(data => data));
  }
  //Update Profile
  public updateAccountDetail(value): Observable<any> {
    // if (value.dob){
    //   var dateString = value.dob.month+"/"+value.dob.day+"/"+value.dob.year   
    // // }
    // const params = {
    //   "fullName": value.fullName,
    //   "dob": value.dob,
    //   "city": value.city,
    //   "state":value.state,
    //   "country":value.country,
    //   "phoneNumber":value.phoneNumber,
    //   "gender":value.gender,
    //   "sportsPreference":value.sportsPreference
    // } 
    return this.http.put<any>(EditAccountInfo, value)
        .pipe(tap(res => this.AccountInfo()))
  }

  //Update Password
  public updatePassword(value): Observable<any>{
    const params = {
      "oldpassword": value.oldPassword,
      "newpassword":  value.newPassword
    } 
    return this.http.put<any>(UpdatePassword, params)
  }

  //Update Profile
  public updateProfileImage(value): any {
    const headers = new HttpHeaders().append('Content-Type', 'multipart/form-data');
    return this.http.post<any>(profileImage, value).pipe(map(res => this.AccountInfo()))
  }

  public uploadAgeVerificationImage(value, type, side): any {
    const headers = new HttpHeaders().append('Content-Type', 'multipart/form-data');
    return this.http.post<any>(ageverificationImage+'/'+type+'/'+side, value);
  }

  public validateveficationdocs(): any {
    return this.http.post<any>(validateveficationdocs, null);
  }

  //Reset Password
  public resetPassword(value): Observable<any>{
    const params = {
      "email": value.email,
      "token":  value.token,
      "password": value.newPassword
    } 
    return this.http.put<any>(ResetPassword, params)
  }
}
