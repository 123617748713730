import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Observable, Subscriber, BehaviorSubject, of, from } from 'rxjs';
import { map, tap, startWith } from 'rxjs/operators';
import { faq, privacyPolicy, termsOfService, aboutus, TrackUrl, referafriend
	, LocationInfo, LeaderboardUrl,nflLeaderboardUrl, respGaming, withdrawlHelp,depositHelp
	, howToPlay, CheckDailyLimits, content_base_url, PromoContents, Banners } from '../constants';
import { environment } from '../../../environments/environment'

@Injectable({
	providedIn: 'root'
})
export class GeneralService {

	constructor(private http: HttpClient) { }

	public get faq(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(faq,{ headers}); 
	}
	public get referafriend(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		const sessionToken = localStorage.getItem("sessionToken");
		return this.http.get(referafriend,{ headers}); 
	}
	public get privacyPolicy(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(privacyPolicy,{ headers }); 
	}
	public get termsOfService(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(termsOfService,{ headers }); 
	}
	public get aboutService(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(aboutus,{ headers}); 
	}
	public get responsibleGaming(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(respGaming,{ headers}); 
	}

	public get withdrawlHelp(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(withdrawlHelp,{ headers}); 
	}
	public get getDepositHelp(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString())
		return this.http.get(depositHelp,{ headers}); 
	}

	public get howToPlay(): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
		headers.set('cid', (5).toString());
		return this.http.get(howToPlay,{ headers}); 
	}

	public getcontentbytype(content_type: string): Observable<any>{
		let content = this.http.get<any>(content_base_url+content_type).pipe(map(data => data));
		return content ;
	}
	public checkdailylimits(): Observable<any> {
		let balanceInfo = this.http.get<any>(CheckDailyLimits).pipe(map(data => data));
		return balanceInfo; 
	}

	// public get howToScore(league): Observable<any> {
	// 	const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
	// 	headers.set('cid', (5).toString());
	// 	var url = environment.API_URL+"content/PTT_CAC_HOWTOSCORE_"+league;
	// 	return this.http.get(url,{ headers}); 
	// }

	public tracker(data): Observable<any> {
		return this.http.post<any>(TrackUrl, data);
	} 
	
	public get locationInfo(): Observable<any> {
		var hours = 1; // Reset when storage is more than 1hour
		var now = new Date().getTime();
		var setupTime = localStorage.getItem('locationInfoTime');

		if (setupTime == null || (now-Number(setupTime) > hours*60*60*1000)) {
			// let info: any = this.http.get<any[]>(LocationInfo).pipe(map(data => data));
			// info.subscribe(next =>{ 
			// 	localStorage['locationInfo'] = JSON.stringify(next);
			// 	localStorage.setItem('locationInfoTime', now.toString());
			// 	return of(JSON.parse(localStorage.getItem('locationInfo')));
			// });
			this.http.get<any[]>(LocationInfo).pipe(map(data => {
				localStorage['locationInfo'] = JSON.stringify(data);
				localStorage.setItem('locationInfoTime', now.toString());
				return of(JSON.parse(localStorage.getItem('locationInfo')));
			}));
		} else{
			return of(JSON.parse(localStorage.getItem('locationInfo')));
		}
	}

	public LocationInfo(): Observable<any> {
		let locationInfoURL= LocationInfo;
		return this.http.get<any>(locationInfoURL).pipe(map(data => data));
	}

	public GetLeaderboardData(): Observable<any> {
		return this.http.get<any>(LeaderboardUrl).pipe(map(data => data));
	}

	public GetnflLeaderboardData(): Observable<any> {
		return this.http.get<any>(nflLeaderboardUrl).pipe(map(data => data));
	}

	public GetPromoContents(): Observable<any> {
		return this.http.get<any>(PromoContents).pipe(map(data => data));
	}
	
	public CustomerSupport_GetAllTickets(email): Observable<any> {
		var url = environment.FRESHDESK_BASEURL+"?email="+email+"&include=description";
		//var url = "https://newaccount1603908299081.freshdesk.com/api/v2/tickets?email=prak7@myorigami.co&&include=description";
	
		return this.http.get<any>(url).pipe(map(data => data));
	}
	
	public CustomerSupport_CreateTicket(values): Observable<any> {
		const payload = {
			"description": values.issuedescription, 
			"subject": values.issuetype, 
			"cc_emails": [], 
			"status": 2, 
			"priority": 2, 
			"email": values.email
		};
		var url = environment.FRESHDESK_BASEURL;
		return this.http.post<any>(url, payload).pipe(map(data => data));
	}

	public CustomerSupport_CreateTicketInThreadReply(ticketId, replytext, requester_id:number): Observable<any> {
		var url = environment.FRESHDESK_BASEURL+"/"+ticketId+"/reply";
		const payload  = {
			"body": replytext, 
			"user_id": Number(requester_id)
		};
		return this.http.post<any>(url, payload).pipe(map(data => data));
	}

	public CustomerSupport_GetTicketThreadDiscussion(ticketId): Observable<any> {
		var url = environment.FRESHDESK_BASEURL+"/"+ticketId+"/conversations";
		return this.http.get<any>(url).pipe(map(data => data));
	}

	public GetNCAA_BannerInfo(){
		//let url="https://sqorr.blob.core.windows.net/ncaafbteams/ncaa_teams.json"
		let url = environment.API_URL+"bannerclientlist"
		 return this.http.get<any>(url).pipe(map(data => data));
	}

	public playerStatsByMatchupId(id, pcid): Observable<any> {
		var url = environment.API_URL+"playerstatsbymatchupid?matchup_id="+id+"&pcid="+pcid;
		return this.http.get<any>(url).pipe(map(data => data));
	}

	public playerStats(body): Observable<any> {
		var url = environment.API_URL+"playerstats?id="+body.id[0]+"&id="+body.id[1];
		return this.http.get<any>(url).pipe(map(data => data));
	}

	public GetBanners(body): Observable<any> {
		return this.http.get<any>(`${Banners}?screen=${body.screen}`).pipe(map(data => data));
	}

	
}