import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  hideFooter: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const id = params['isMobileView'];
      //console.log(id);
      if (!isNaN(id)) {
        this.hideFooter = true;
      } 
    });
  }

  isDisplayMobileFooter() {
    const ignoreRoutes = ['/profile', '/profile/edit', '/profile/changepassword', '/add-funds', '/transaction', '/checkout', 'playtactoe']
    let temoUrl = this.router.url.split("/");
    if (temoUrl[1] != "playtactoe") {
      if (ignoreRoutes.includes(this.router.url)) return false;
      else return true;
    } else {
      if (ignoreRoutes.includes(temoUrl[1])) return false;
      else return true;
    }
  }

}
