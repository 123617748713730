import { Component } from '@angular/core';
import { MixpanelService } from "./shared/services/mixpanel.service";
import { environment } from "./../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Play Sqor';

  constructor(
    private mixpanelService: MixpanelService) {   }

    async ngOnInit() {
      this.mixpanelService.init(environment.MIXPANEL_TOKEN);
     
    }
}
