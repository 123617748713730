import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth.service'
import { environment } from '../../../environments/environment'

export const InterceptorSkipHeader = 'X-override-Interceptor';
@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(private AuthenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with token if user is logged in and request is to api url
        const sessionToken = localStorage.getItem("token");
        const isLoggedIn = this.AuthenticationService.isLoggedIn();
        if (isLoggedIn && sessionToken) {
            if (request.url.includes('freshdesk')) {
                request = request.clone({
                    setHeaders: {
                        'Authorization': 'basic '+environment.FRESHDESK_KEY
                    }
                });
            }
            else{
                request = request.clone({
                    setHeaders: {
                        'Authorization': 'bearer '+sessionToken
                    }
                });
            }
        } 
        request = request.clone({ 
            headers: request.headers.set('cid', environment.company_id),
        });
        return next.handle(request);
    }
}