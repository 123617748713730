import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './shared/helper/auth.guard';

import { MainComponent } from './main/main.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cards/all',
    pathMatch: 'full'
  },
  {
    path : '',
    component : MainComponent,
    children: [ 
      {
        path: 'cards',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'cards/:sport_name',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      // {
      //   path: 'cards/:sport_name/:league_abbreviation',
      //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      // },
      // {
      //   path: 'cards/:sport_name/:league_abbreviation/:card_type',
      //   loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      // },
      {
        path: '',
        loadChildren: () => import('./carddetails/carddetails.module').then(m => m.CarddetailsModule),
      },
      {
        path: 'sqorr-tv',
        loadChildren: () => import('./sqorr-tv/sqorr-tv.module').then(m => m.SqorrTvModule)
      },
      {
        path: 'tipsntricks',
        loadChildren: () => import('./tipsntricks/tipsntricks.module').then(m => m.TipsntricksModule)
      },
      {
        path: '',
        loadChildren: () => import('./customersupport/customersupport.module').then(m => m.CustomersupportModule),
        canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule)
      },
      {
        path: 'mycards',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule)
      },
      {
        path: 'mycards/:section',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule),
      },
      {
        path: 'mycards/:sport_name',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule),
      },
      {
        path: 'mycards/:sport_name/:status',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule),
      },
      {
        path: 'mycards/:sport_name/:status/:card_type',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule),
      },
      {
        path: 'cards/:sport_name/:status',
        loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule),
      },
      // {
      //   path: 'mycards/:id',
      //   loadChildren: () => import('./mycards/mycards.module').then(m => m.MycardsModule)
      // },
      
      {
        path: 'promos',
        loadChildren: () => import('./promos/promos.module').then(m => m.PromosModule)
      },
      {
        path: 'setting',
        loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'card/:id',
        loadChildren: () => import('./games/games.module').then(m => m.GamesModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: '',
        loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'leaderboard',
        loadChildren: () => import('./leaderboard/leaderboard.module').then(m => m.LeaderboardModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'NFL-Leaderboard',
        loadChildren: () => import('./NFLleaderboard/nflleaderboard.module').then(m => m.nflLeaderboardModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: 'cards',
    redirectTo: 'cards',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
