import { Injectable } from '@angular/core';
import * as moment from "moment";
import { Router } from '@angular/router';
import { SignUpURL, LoginURL, ForgotPassURL, SocialLoginURL, VerifyEmail, LogoutURL } from '../constants';
import { Signup, Login, ForgotPassword } from '../model/auth';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public locationPopup: boolean = false

  constructor(private http: HttpClient,
    private router: Router) { }
  
  // Sign Up 
  public signup(value): Observable<any> {
        
    // if (value.dob){
    //   var dateString = value.dob.month+"/"+value.dob.day+"/"+value.dob.year   
    // }

    const params = {
      "email": value.email,
      "password": value.password,
      "fullName": value.fullName,
      "dob": value.dob,// != undefined ? dateString : "",
      "street_address": value.street_address,
      "street_address2": value.street_address2,
      "city": value.city,
      "state": value.state,
      "postal_code": value.postal_code,
      "phoneNumber": value.phoneNumber,
      "countryCode": 1,
      "promoCode": value.promoCode || '',
      "userOrigin": (localStorage.getItem("promotercode") != null) ? localStorage.getItem("promotercode") : "none",
      "platformOrigin":"Web",
      "signUpOrigin": value.signUpOrigin,
      "signUpFor": value.signUpFor,
      "referrerCode": (localStorage.getItem("referrelCode") != null) ? localStorage.getItem("referrelCode") : "",
      "facebook":{
          "email": value.email,
          "id": value.fbId,
          "token": value.fbToken,
          "displayName":value.fullName
      },
      "google":{
          "email": value.email,
          "id": value.gId,
          "token": value.gToken,
          "displayName":value.fullName
      },
      "metrics":{
        "platform":"Web",
        "appVersion":"0.0.1"
      }
    };
    return this.http.post<any>(SignUpURL, params)
        .pipe(tap(res => this.setSession(res)))
  }
  
  // Login
  public login(value): Observable<any> {
    const params = {
      "email": value.email,
      "password": value.password,
      "metrics":{
        "platform":"Web",
        "appVersion":"0.0.1"
      }
    };

    return this.http.post<any>(LoginURL, params).pipe(tap(res => this.setSession(res)));
  }

  public socialLogin(value): Observable<any> {    
    const params = {
    "email": value.email,
    "source": value.provider,
    "metrics":{
      "platform":"Web",
      "appVersion":"0.0.1"
      }
    }

    return this.http.post<any>(SocialLoginURL, params).pipe(tap(res => this.setSession(res)))
  }

  // Forgot Password
  public forgotPassword(value): Observable<ForgotPassword> {
    const params = {
      "email": value.email
    };

    return this.http.post<ForgotPassword>(ForgotPassURL, params).pipe(tap(res => res))
  }  
  
  // Forgot Password
  public VerifyEmail(value): Observable<any> {
    const params = {
      "email": value.email,
      "token" : value.token
    };

    return this.http.put<any>(VerifyEmail, params).pipe(tap(res => res))
  }  

  // Set session
  private setSession(authResult) {
    localStorage.removeItem("referrelCode");
    localStorage.setItem('token', authResult.token);
    localStorage.setItem('accountInfo', JSON.stringify(authResult.account));
  }
  
  // Logout
  public logout() : void {

    //delete api calling
    this.http.delete<any>(LogoutURL).subscribe((data) => {
      console.log(data)
    },
    (err) =>{
      console.log(err)
    });

    //removing token from localstorage
    localStorage.removeItem("token");
    localStorage.clear();
    this.router.navigate(['/cards/all'])
  }
  
  // Is logged in
  public isLoggedIn() : boolean {
    return !!localStorage.getItem('token');
  }
  
  // Is logged out
  public isLoggedOut() {
    return !this.isLoggedIn();
  }
  
  // // Error handling 
  // handleError(error) {
  //   let errorMessage = '';
  //   if(error.error instanceof ErrorEvent) {
  //     // Get client-side error
  //     errorMessage = error.error.message;
  //   } else {
  //     // Get server-side error
  //     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //   }
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }

}
