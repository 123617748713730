import { environment } from './../../environments/environment';

// Authentication 
export const SignUpURL = environment.API_URL+"account/create";
export const LoginURL = environment.API_URL+"login";
export const SocialLoginURL = environment.API_URL+"sociallogin";
export const ForgotPassURL = environment.API_URL+"forgotPassword";
export const LogoutURL = environment.API_URL+"logout";
// Account
export const ValidateLocation =environment.API_URL+"validatelocation";
export const AccountInfo = environment.API_URL+"myinfo";
export const ProfileInfo = environment.API_URL+"profileinfo";
export const EditAccountInfo=environment.API_URL+"account/update";
export const UpdatePassword=environment.API_URL+"account/updatepassword";
export const ResetPassword=environment.API_URL+"account/resetpassword";
export const VerifyEmail=environment.API_URL+"account/verifyemail";
export const profileImage=environment.API_URL+"account/profileimage";
export const ageverificationImage=environment.API_URL+"upload";
export const validateveficationdocs=environment.API_URL+"validateveficationdocs";
//Card
export const SportsURL =environment.API_URL+"sports";
export const LeagueURL =environment.API_URL+"league";
export const cardURL = environment.API_URL+"cards";
export const SQORRTVURL = environment.API_URL+"playsqorrtv";
export const Mycards = environment.API_URL+"mycards";
export const MycardsById = environment.API_URL+"mycardsbyid";
//Pages
export const faq = environment.API_URL+"content/FAQ";
export const referafriend = environment.API_URL+"myreferrals";
export const privacyPolicy = environment.API_URL+"content/PRIVACY_POLICY";
export const termsOfService= environment.API_URL+"content/TOS";
export const aboutus= environment.API_URL+"content/ABOUT";
export const respGaming= environment.API_URL+"content/RESPONSIBLE_GAMING";
export const withdrawlHelp= environment.API_URL+"content/WITHDRAWAL_HELP";
export const depositHelp= environment.API_URL+"content/DEPOSIT_HELP";
export const howToPlay= environment.API_URL+"content/PTT_CAC_HOWTOPLAY";
export const content_base_url= environment.API_URL+"content/";

// Transaction
//export const TransactionURL  = environment.API_URL+"account/transactions";
export const TransactionURL  = environment.API_URL+"u/ts";
export const TransactionByIdURL  = environment.API_URL+"account/transactionsbyid";
export const PurchaseCardURL  = environment.API_URL+"account/purchasecard";
export const AddFundURL  = environment.API_URL+"account/addfunds";
export const AddPaypalFundURL = environment.API_URL+"account/addpaypalfunds";
export const AddCardNewURL  = environment.API_URL+"account/addfunds/newcard";
export const AddCardURL  = environment.API_URL+"account/addcard";
export const ListCardURL = environment.API_URL+"account/cards";
export const EditCardURL = environment.API_URL+"account/editcard";
export const DeletetCardURL = environment.API_URL+"account/deletecard";
export const SendEmailVerificationLinkURL = environment.API_URL+"sendemailverificationlink";
export const ProfileInfoURL = environment.API_URL+"profileinfo";
export const ListCredCardURL = environment.API_URL+"account/cards";
export const CheckDailyLimits = environment.API_URL+"checkdailylimit";
export const GetCredCardInfoURL = environment.API_URL+"account/card";

export const WithdrawFundURL = environment.API_URL+"account/withdrawfunds";
export const ListBankAccountsURL = environment.API_URL+"account/bankaccounts";
export const withdrawViaNewACHURL = environment.API_URL+"account/addACHAccount";
export const withdrawViaACHURL = environment.API_URL+"account/withdrawviaACH";
export const deleteACHURL = environment.API_URL+"account/deleteACHAccount";

export const LocationInfo = environment.API_URL+"locationinfo";
export const ValidatePromocodeUrl = environment.API_URL+"validatepromocode";
export const TrackUrl = environment.API_URL+"tracker";
export const LeaderboardUrl = environment.API_URL+"lb";
export const nflLeaderboardUrl = environment.API_URL+"nfllb";
export const ClaimGEPPrizeUrl = environment.API_URL+"claimgepprize";
export const PlayBingoUrl = environment.API_URL+"playbingo";
export const ClaimGWPUrl = environment.API_URL+"claimgwp";
export const BingoPlayInfoUrl = environment.API_URL+"bingoplayinfo";
export const SettleCardUrl = environment.API_URL+"p/shc/";
export const HowToVideo = environment.API_URL+"howtovideos";

export const BalanceInfo = environment.API_URL+"balanceinfo";
export const ExistBankAccounts = environment.API_URL+"account/nuveibankaccounts";
export const NuveiAccountCaptureUrl = environment.API_URL+"account/getnuveiaccountcaptureurl";
export const GetNuveiibtURL = environment.API_URL+"account/getnuveiibturl";
export const NuveiWithdrawFunds = environment.API_URL+"nuveiwithdrawfunds";
export const deleteAccount = environment.API_URL+"account/deletecard";
export const validateIPAddress = environment.VALIDATE_IP_API_URL+"validateIPAddress";

export const PromoContents = environment.API_URL+"getpromocontents";
export const Banners = environment.API_URL+"banners";


export const statesUrl="https://imgstore.azureedge.net/sitefiles/States.json";
export const encryption_decryption_password = environment.TEXT_ENCRYPTION_DECRYPTION_PASSWORD;
